* {
  font-family: "Inter", "Helveltica", sans-serif;
}

#root {
  padding-bottom: 60px;
}

.container {
  width: 90%;
}

body {
  margin: 0;
  padding: 0;
  background: #f8f9fa;
}

svg {
  fill: currentColor;
}

/* purple: 8549BA
   green:7AC70C
   grey: 4c4c4c
   light grey: CFCFCF
   kiwi: 7AC70C

*/

.logo {
  width: 10em;
}

.google-icon {
  position: relative;
  margin: 0 8px;
  width: 20px;
}

a .btn,
a {
  text-decoration: none;
}

.btn {
  padding: 8px 16px;
  background-color: #4c4c4c;
  color: white;
  font-weight: 600;
  border: 0;
  border-radius: 24px;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
}

.btn:active,
.btn:hover {
  outline: none;
}

.btn span {
  color: white;
}

.btn.btn-inverted {
  color: #8549ba;
  background-color: white;
}

.btn.btn-outline {
  color: #8549ba;
  background-color: transparent;
  border: 1px solid #8549ba;
}

.btn.btn-outline:hover {
  background-color: #a560eb;
  color: white;
}

.btn.btn-primary {
  background-color: #8549ba;
}

.btn.btn-primary:hover {
  background-color: #a560eb;
}

.btn.btn-primary:active {
  background-color: #ca94ff;
}

.btn.btn-large {
  border-radius: 32px;
  padding: 16px 32px;
}

.btn.btn-small {
  border-radius: 32px;
  padding: 6px 16px;
}

.btn.btn-icon {
  width: 100%;
}

.btn.btn-disabled-color {
  background-color: #8549ba5c;
  pointer-events: none;
  cursor: not-allowed;
}

.vertical-offset-1 {
  margin-top: 0.5em;
}

.vertical-offset-2 {
  margin-top: 1em;
}

.vertical-offset-3 {
  margin-top: 1.5em;
}

.vertical-offset-4 {
  margin-top: 2em;
}

.vertical-offset-5 {
  margin-top: 4em;
}

.you-will-receive-alert {
  margin-top: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4c4c4c;
  font-weight: 600;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #4c4c4c;
    font-weight: 600;
    margin-bottom: 0;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.375rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.875rem;
  }
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.navbar {
  background-color: #8549ba;
  padding: 12px 0;
}

.navbar-items {
  height: 1em;
  margin-top: 0px;
  list-style: none;
  padding: 0;
}

.navbar-items li {
  display: inline-block;
}

.navbar .logo {
  width: 7em;
  margin-top: 4px;
}

.navbar-item {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  margin-left: 1em;
}

.navbar-item:hover {
  border-bottom: 2px solid white;
}

.row-new-bot * {
  box-sizing: border-box;
}

.card {
  background: white;
  padding: 2em 1.5em;
  border: 1px solid #e7eaf3;
  border-radius: 5px;
  margin: 1em 0;
  height: 100%;
}

.card-choose {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-custom-bot {
  background: white;
  padding: 2em 1.5em;
  border: 1px solid #e7eaf3;
  border-radius: 5px;
  margin: 1em 0;
  width: 100%;
  box-sizing: border-box;
}

.custom-bot-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card.card-filled {
  background: #8549ba;
  color: white;
}

.card.card-filled .card-title,
.card.card-filled svg,
.card.card-filled p {
  color: white;
}

.card:hover {
  border-color: rgba(192, 55, 255, 0.3);
  box-shadow: 0 0 35px rgba(55, 125, 255, 0.125);
}

.card-title {
  font-weight: 600;
  color: #4c4c4c;
  font-size: 18px;
}

.card-title p {
  color: #4c4c4c;
}

.card-title-button {
  position: relative;
  float: right;
  top: -10px;
}

.card-title svg {
  margin-left: 8px;
  color: #4c4c4c;
}

.chats-list-title-container {
  font-weight: 600;
  color: #4c4c4c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chats-list-title-container svg {
  margin-left: 8px;
  color: #4c4c4c;
}

.btn-delete-chat {
  position: relative;
  color: red;
  background-color: transparent;
  border: 1px solid red;
  padding: 8px 16px;
  font-weight: 600;
  border-radius: 24px;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  transition: all 0.2s ease-in-out;
}

.btn-delete-chat:hover {
  color: #fff;
  background-color: red;
}

.btn-delete-chat--loading {
  color: transparent;
  background-color: transparent;
  border-color: gray;
  opacity: 0.5;
}

.loader {
  position: absolute;
  width: 18px;
  height: 18px;
  margin-left: 4px;
  border: 4px solid gray;
  border-bottom-color: black;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-image {
  width: 50%;
  margin: 2em auto;
}

.card-text {
  text-align: center;
}

.card-list {
  padding: 0;
  margin: 16px 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-list-item {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 12px 0 12px 0;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  color: #4c4c4c;
}

.card-list-item-icon {
  color: #cfcfcf;
  margin-right: 16px;
}

.card-list-item-name {
  color: #4c4c4c;
  flex-grow: 2;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-list-item-name:hover {
  text-decoration: underline;
}

.card-list-item-status {
  color: #4c4c4c;
  flex-grow: 1;
  position: relative;
  width: 14px;
  height: 14px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.form-group {
  width: 100%;
  margin-bottom: 2em;
}

.form-group-input {
  position: relative;
}

.form-group label {
  width: 100%;
  display: block;
  margin: 1em 0;
  font-weight: 600;
}

.over-goals .form-group label {
  font-weight: normal;
}

::placeholder {
  color: #bebebe;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bebebe;
}

::-ms-input-placeholder {
  color: #bebebe;
}

.form-group .input-range {
  margin: 2em 0 4em 0;
}

.checkbox-container {
  padding: 0.5em 0;
}

.checkbox-container input {
  margin-right: 1em;
}

.checkbox-container label {
  display: inline;
  font-weight: 400;
}

.input-range__slider {
  appearance: none;
  background: #b3f449;
  border: 1px solid #b3f449;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #4c4c4c;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 1.3rem;
}

.input-range__label--max {
  right: 1.3rem;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #b3f449;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 95%;
}

.radio-toolbar-column {
  flex-direction: column;
}

.radio-toolbar-row {
  flex-direction: row;
}

.radio-toolbar.radio-toolbar-column label {
  display: block;
  margin: 0 10px 8px 0;
  width: 100%;
  box-sizing: border-box;
}

.radio-toolbar.radio-toolbar-row label {
  display: block;
  width: 90%;
  margin: 0;
}

.radio-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;
}

.radio-toolbar input[type="radio"] {
  display: none;
}

span.highlight {
  color: #8549ba;
  text-decoration: underline;
  padding: 4px;
}

.radio-toolbar label {
  background-color: white;
  padding: 1em 1em;
  margin: 0 10px 0 0;
  color: #8549ba;
  font-weight: 600;
  border: 1px solid #8a05be;
  border-radius: 1em;
  text-align: center;
  flex: 1;
  cursor: pointer;
}

.radio-toolbar label:hover {
  background-color: #8a05be;
  color: white;
}

.radio-toolbar input[type="radio"]:checked + label {
  color: white;
  background-color: #8a05be;
  border-color: #4e016d;
}

.swal2-container.swal2-shown {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.swal2-container.swal2-shown .btn {
  outline: none;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(
    .swal2-top-left
  ):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(
    .swal2-center-left
  ):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(
    .swal2-bottom-end
  ):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen)
  > .swal2-modal {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.bubble {
  display: block;
  position: absolute;
  cursor: pointer;
  right: 1em;
}

.bubble:hover:after {
  background-color: #7ac70c;
}

.bubble:after {
  content: "";
  background-color: #7ac70c;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  display: block;
  top: 1px;
  left: 1px;
}

.bubble .bubble-outer-dot {
  margin: 1px;
  display: block;
  position: absolute;
  text-align: center;
  opacity: 1;
  background-color: rgba(122, 199, 12, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

.bubble .bubble-inner-dot {
  position: absolute;
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(122, 199, 12, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

.bubble .bubble-inner-dot:after {
  content: "";
  display: block;
  text-align: center;
  opacity: 1;
  background-color: rgba(122, 199, 12, 0.4);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  -webkit-animation: bubble-pulse 1.5s linear infinite;
  -moz-animation: bubble-pulse 1.5s linear infinite;
  -o-animation: bubble-pulse 1.5s linear infinite;
  animation: bubble-pulse 1.5s linear infinite;
}

@-webkit-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-moz-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@-o-keyframes bubble-pulse {
  0% {
    transform: scale(1);
    opacity: 0.75;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

/* PLAN */
.planContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 1em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.plan {
  background: white;
  width: 20em;
  box-sizing: border-box;
  text-align: center;
  margin: 1em;
  margin-bottom: 1em;
}

.plan.plan-recommended {
  border: 1px solid #8549ba;
  box-shadow: 0 0 7px 3px rgba(144, 29, 173, 0.15);
  width: 24em;
}
.recommended-badge {
  background: #8549ba;
  padding: 0.5em;
  color: white;
  font-weight: 600;
}
.plan .titleContainer {
  background-color: #f3f3f3;
  padding: 1em;
}
.plan .titleContainer .title {
  font-size: 1.45em;
  text-transform: uppercase;
  color: #2d3b48;
  font-weight: 700;
}
.plan .infoContainer {
  padding: 1em;
  color: #2d3b48;
  box-sizing: border-box;
}
.plan .infoContainer .price {
  font-size: 1.35em;
  padding: 0 0;
  font-weight: 600;
  margin-top: 0;
  display: inline-block;
  width: 80%;
  color: #8549ba;
}
.plan .infoContainer .price p {
  font-size: 1.55em;
  display: inline-block;
  margin: 0;
}
.plan .infoContainer .price span {
  font-size: 0.9em;
  display: inline-block;
}

.plan .infoContainer .price .saving {
  font-size: 0.6em;
  font-style: italic;
  color: #2d3b48;
  width: 100%;
}
.plan .infoContainer .desc {
  padding-bottom: 1em;
  border-bottom: 2px solid #f3f3f3;
  margin: 0 auto;
  width: 90%;
}
.plan .infoContainer .desc em {
  font-size: 1em;
  font-weight: 500;
}
.plan .infoContainer .features {
  font-size: 1em;
  list-style: none;
  padding-left: 0;
}
.plan .infoContainer .features li {
  padding: 0.5em;
}
.plan .infoContainer .selectPlan {
  border: 2px solid #1abc9c;
  padding: 0.75em 1em;
  border-radius: 2.5em;
  cursor: pointer;
  transition: all 0.25s;
  margin: 1em auto;
  box-sizing: border-box;
  max-width: 70%;
  display: block;
  font-weight: 700;
}
.plan .infoContainer .selectPlan:hover {
  background-color: #1abc9c;
  color: white;
}

@media screen and (max-width: 25em) {
  .planContainer {
    margin: 0;
  }
  .planContainer .plan {
    width: 100%;
    margin: 1em 0;
  }
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  border: 1px solid #7e7e7e;
  outline: 0;
  border-radius: 3px;
  background: white;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.purchase-resume {
  margin-bottom: 2em;
}
.purchase-resume span {
  width: 100%;
  display: inline-block;
}

.purchase-resume span.bold {
  font-weight: 600;
}

.cards-logo {
  width: 7em;
  display: block;
}

.secure-purchase {
  color: #7ac70c;
  margin: 0 0 2em 0;
}

.only-vip {
  background-color: white;
  padding: 1em 1em;
  margin: 0 10px 0 0;
  color: #8549ba;
  font-weight: 600;
  border: 1px dashed #8a05be;
  border-radius: 1em;
  text-align: center;
  flex: 1;
  position: relative;
}

.label-vip {
  font-size: 0.8em;
  background: #8549ba;
  color: white;
  border-radius: 1em;
  text-align: center;
  margin: 0 0 0;
  padding: 5px;
  position: absolute;
  top: 10px;
  left: -20px;
  transform: rotate(0deg);
  cursor: pointer;
}

.information {
  padding: 15px;
  width: 90%;
  margin: 1em 1em 1em 0;
  background-color: rgb(244, 234, 255);
  color: #8a05be;
  border: 1px dashed #4e016d;
}

.label-inline-vip {
  font-size: 0.8em;
  background: #8549ba;
  font-weight: 600;
  color: white;
  text-align: center;
  margin: 0;
  margin-bottom: 16px;
  width: max-content;
  padding: 8px 10px;
  border-radius: 16px;
}

.label-inline-free {
  font-size: 0.8em;
  background: rgb(192, 192, 192);
  color: #4c4c4c;
  border-radius: 1em;
  text-align: center;
  margin: 0;
  margin-bottom: 16px;
  width: max-content;
  padding: 8px 10px;
  border-radius: 16px;
}

.form-vip {
  padding: 15px;
  width: 90%;
  margin: 1em 1em 1em 0;
  background-color: rgb(244, 234, 255, 0.5);
  border: 1px dashed #4e016d;
}

.swal2-popup .swal2-actions {
  flex-direction: column;
}

.swal2-popup .swal2-actions .btn {
  margin-top: 1em;
}

.text-center {
  text-align: center;
}

form h4 {
  margin-top: 4em;
  font-weight: bold;
  text-transform: uppercase;
}

:focus {
  outline: none;
}

select {
  width: 98%;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
}

/* necessary to give position: relative to parent. */
input[type="text"] {
  width: 100%;
  color: #333;
  box-shadow: 0 0 0 0 transparent;
  font-size: 18px;
  box-sizing: border-box;
  letter-spacing: 1px;
}

:focus {
  outline: none;
}

input[type="text"] {
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.effect-1,
.effect-2,
.effect-3 {
  border: 0;
  padding: 7px 0;
  border-bottom: 1px solid #ccc;
}

.effect-1 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #8549ba;
  transition: 0.4s;
}
.effect-1:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}

.effect-2 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-2:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}

.effect-3 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 99;
}
.effect-3 ~ .focus-border:before,
.effect-3 ~ .focus-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-3 ~ .focus-border:after {
  left: auto;
  right: 0;
}
.effect-3:focus ~ .focus-border:before,
.effect-3:focus ~ .focus-border:after {
  width: 50%;
  transition: 0.4s;
}

.effect-4,
.effect-5,
.effect-6 {
  border: 0;
  padding: 5px 0 7px;
  border: 1px solid transparent;
  border-bottom-color: #ccc;
  transition: 0.4s;
}

.effect-4:focus,
.effect-5:focus,
.effect-6:focus {
  padding: 5px 14px 7px;
  transition: 0.4s;
}

.effect-4 ~ .focus-border {
  position: absolute;
  height: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.4s;
  z-index: -1;
}
.effect-4:focus ~ .focus-border {
  transition: 0.4s;
  height: 36px;
  border: 2px solid #4caf50;
  z-index: 1;
}

.effect-5 ~ .focus-border {
  position: absolute;
  height: 36px;
  bottom: 0;
  left: 0;
  width: 0;
  transition: 0.4s;
}
.effect-5:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
  border: 2px solid #4caf50;
}

.effect-6 ~ .focus-border {
  position: absolute;
  height: 36px;
  bottom: 0;
  right: 0;
  width: 0;
  transition: 0.4s;
}
.effect-6:focus ~ .focus-border {
  width: 100%;
  transition: 0.4s;
  border: 2px solid #4caf50;
}

.effect-7,
.effect-8,
.effect-9 {
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}

.effect-7 ~ .focus-border:before,
.effect-7 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-7 ~ .focus-border:after {
  top: auto;
  bottom: 0;
}
.effect-7 ~ .focus-border i:before,
.effect-7 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.6s;
}
.effect-7 ~ .focus-border i:after {
  left: auto;
  right: 0;
}
.effect-7:focus ~ .focus-border:before,
.effect-7:focus ~ .focus-border:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
}
.effect-7:focus ~ .focus-border i:before,
.effect-7:focus ~ .focus-border i:after {
  top: 0;
  height: 100%;
  transition: 0.6s;
}

.effect-8 ~ .focus-border:before,
.effect-8 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.3s;
}
.effect-8 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.effect-8 ~ .focus-border i:before,
.effect-8 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-8 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.effect-8:focus ~ .focus-border:before,
.effect-8:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.effect-8:focus ~ .focus-border i:before,
.effect-8:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

.effect-9 ~ .focus-border:before,
.effect-9 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.2s;
  transition-delay: 0.2s;
}
.effect-9 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
  transition-delay: 0.6s;
}
.effect-9 ~ .focus-border i:before,
.effect-9 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.2s;
}
.effect-9 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  transition-delay: 0.4s;
}
.effect-9:focus ~ .focus-border:before,
.effect-9:focus ~ .focus-border:after {
  width: 100%;
  transition: 0.2s;
  transition-delay: 0.6s;
}
.effect-9:focus ~ .focus-border:after {
  transition-delay: 0.2s;
}
.effect-9:focus ~ .focus-border i:before,
.effect-9:focus ~ .focus-border i:after {
  height: 100%;
  transition: 0.2s;
}
.effect-9:focus ~ .focus-border i:after {
  transition-delay: 0.4s;
}

.effect-10,
.effect-11,
.effect-12,
.effect-13,
.effect-14,
.effect-15 {
  border: 0;
  padding: 7px 15px;
  border: 1px solid #ccc;
  position: relative;
  background: transparent;
}

.effect-10 ~ .focus-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ededed;
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}
.effect-10:focus ~ .focus-bg {
  transition: 0.5s;
  opacity: 1;
}

.effect-11 ~ .focus-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-11:focus ~ .focus-bg {
  transition: 0.3s;
  width: 100%;
}

.effect-12 ~ .focus-bg {
  position: absolute;
  left: 50%;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-12:focus ~ .focus-bg {
  transition: 0.3s;
  width: 100%;
  left: 0;
}

.effect-13 ~ .focus-bg:before,
.effect-13 ~ .focus-bg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-13:focus ~ .focus-bg:before {
  transition: 0.3s;
  width: 50%;
}
.effect-13 ~ .focus-bg:after {
  left: auto;
  right: 0;
}
.effect-13:focus ~ .focus-bg:after {
  transition: 0.3s;
  width: 50%;
}

.effect-14 ~ .focus-bg:before,
.effect-14 ~ .focus-bg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-14:focus ~ .focus-bg:before {
  transition: 0.3s;
  width: 50%;
  height: 100%;
}
.effect-14 ~ .focus-bg:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.effect-14:focus ~ .focus-bg:after {
  transition: 0.3s;
  width: 50%;
  height: 100%;
}

.effect-15 ~ .focus-bg:before,
.effect-15 ~ .focus-bg:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-15:focus ~ .focus-bg:before {
  transition: 0.3s;
  width: 50%;
  left: 0;
  top: 0;
  height: 100%;
}
.effect-15 ~ .focus-bg:after {
  left: auto;
  right: 50%;
  top: auto;
  bottom: 50%;
}
.effect-15:focus ~ .focus-bg:after {
  transition: 0.3s;
  width: 50%;
  height: 100%;
  bottom: 0;
  right: 0;
}

.effect-16,
.effect-17,
.effect-18 {
  border: 0;
  padding: 4px 0;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}

.effect-16 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-16:focus ~ .focus-border,
.has-content.effect-16 ~ .focus-border {
  width: 100%;
  transition: 0.4s;
}
.effect-16 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-16:focus ~ label,
.has-content.effect-16 ~ label {
  top: -16px;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.effect-17 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-17:focus ~ .focus-border,
.has-content.effect-17 ~ .focus-border {
  width: 100%;
  transition: 0.4s;
  left: 0;
}
.effect-17 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-17:focus ~ label,
.has-content.effect-17 ~ label {
  top: -16px;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.effect-18 ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 99;
}
.effect-18 ~ .focus-border:before,
.effect-18 ~ .focus-border:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-18 ~ .focus-border:after {
  left: auto;
  right: 0;
}
.effect-18:focus ~ .focus-border:before,
.effect-18:focus ~ .focus-border:after,
.has-content.effect-18 ~ .focus-border:before,
.has-content.effect-18 ~ .focus-border:after {
  width: 50%;
  transition: 0.4s;
}
.effect-18 ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 9px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-18:focus ~ label,
.has-content.effect-18 ~ label {
  top: -16px;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.effect-19,
.effect-20,
.effect-21 {
  border: 1px solid #ccc;
  padding: 7px 14px;
  transition: 0.4s;
  background: transparent;
}

.effect-19 ~ .focus-border:before,
.effect-19 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: -1px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-19 ~ .focus-border:after {
  top: auto;
  bottom: 0;
}
.effect-19 ~ .focus-border i:before,
.effect-19 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.6s;
}
.effect-19 ~ .focus-border i:after {
  left: auto;
  right: 0;
}
.effect-19:focus ~ .focus-border:before,
.effect-19:focus ~ .focus-border:after,
.has-content.effect-19 ~ .focus-border:before,
.has-content.effect-19 ~ .focus-border:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
}
.effect-19:focus ~ .focus-border i:before,
.effect-19:focus ~ .focus-border i:after,
.has-content.effect-19 ~ .focus-border i:before,
.has-content.effect-19 ~ .focus-border i:after {
  top: -1px;
  height: 100%;
  transition: 0.6s;
}
.effect-19 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-19:focus ~ label,
.has-content.effect-19 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.effect-20 ~ .focus-border:before,
.effect-20 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.3s;
}
.effect-20 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}
.effect-20 ~ .focus-border i:before,
.effect-20 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.4s;
}
.effect-20 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.effect-20:focus ~ .focus-border:before,
.effect-20:focus ~ .focus-border:after,
.has-content.effect-20 ~ .focus-border:before,
.has-content.effect-20 ~ .focus-border:after {
  width: 100%;
  transition: 0.3s;
}
.effect-20:focus ~ .focus-border i:before,
.effect-20:focus ~ .focus-border i:after,
.has-content.effect-20 ~ .focus-border i:before,
.has-content.effect-20 ~ .focus-border i:after {
  height: 100%;
  transition: 0.4s;
}
.effect-20 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-20:focus ~ label,
.has-content.effect-20 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.effect-21 ~ .focus-border:before,
.effect-21 ~ .focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #4caf50;
  transition: 0.2s;
  transition-delay: 0.2s;
}
.effect-21 ~ .focus-border:after {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
  transition-delay: 0.6s;
}
.effect-21 ~ .focus-border i:before,
.effect-21 ~ .focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #4caf50;
  transition: 0.2s;
}
.effect-21 ~ .focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  transition-delay: 0.4s;
}
.effect-21:focus ~ .focus-border:before,
.effect-21:focus ~ .focus-border:after,
.has-content.effect-21 ~ .focus-border:before,
.has-content.effect-21 ~ .focus-border:after {
  width: 100%;
  transition: 0.2s;
  transition-delay: 0.6s;
}
.effect-21:focus ~ .focus-border:after,
.has-content.effect-21 ~ .focus-border:after {
  transition-delay: 0.2s;
}
.effect-21:focus ~ .focus-border i:before,
.effect-21:focus ~ .focus-border i:after,
.has-content.effect-21 ~ .focus-border i:before,
.has-content.effect-21 ~ .focus-border i:after {
  height: 100%;
  transition: 0.2s;
}
.effect-21:focus ~ .focus-border i:after,
.has-conten.effect-21 ~ .focus-border i:after {
  transition-delay: 0.4s;
}
.effect-21 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-21:focus ~ label,
.has-content.effect-21 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #4caf50;
  transition: 0.3s;
}

.effect-22,
.effect-23,
.effect-24 {
  border: 0;
  padding: 7px 15px;
  border: 1px solid #ccc;
  position: relative;
  background: transparent;
}

.effect-22 ~ .focus-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background-color: transparent;
  transition: 0.4s;
  z-index: -1;
}
.effect-22:focus ~ .focus-bg,
.has-content.effect-22 ~ .focus-bg {
  transition: 0.4s;
  width: 100%;
  background-color: #ededed;
}
.effect-22 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-22:focus ~ label,
.has-content.effect-22 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #333;
  transition: 0.3s;
}

.effect-23 ~ .focus-bg:before,
.effect-23 ~ .focus-bg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-23:focus ~ .focus-bg:before,
.has-content.effect-23 ~ .focus-bg:before {
  transition: 0.3s;
  width: 50%;
  height: 100%;
}
.effect-23 ~ .focus-bg:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}
.effect-23:focus ~ .focus-bg:after,
.has-content.effect-23 ~ .focus-bg:after {
  transition: 0.3s;
  width: 50%;
  height: 100%;
}
.effect-23 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-23:focus ~ label,
.has-content.effect-23 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #333;
  transition: 0.3s;
}

.effect-24 ~ .focus-bg:before,
.effect-24 ~ .focus-bg:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  background-color: #ededed;
  transition: 0.3s;
  z-index: -1;
}
.effect-24:focus ~ .focus-bg:before,
.has-content.effect-24 ~ .focus-bg:before {
  transition: 0.3s;
  width: 50%;
  left: 0;
  top: 0;
  height: 100%;
}
.effect-24 ~ .focus-bg:after {
  left: auto;
  right: 50%;
  top: auto;
  bottom: 50%;
}
.effect-24:focus ~ .focus-bg:after,
.has-content.effect-24 ~ .focus-bg:after {
  transition: 0.3s;
  width: 50%;
  height: 100%;
  bottom: 0;
  right: 0;
}
.effect-24 ~ label {
  position: absolute;
  left: 14px;
  width: 100%;
  top: 10px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 0.5px;
}
.effect-24:focus ~ label,
.has-content.effect-24 ~ label {
  top: -18px;
  left: 0;
  font-size: 12px;
  color: #333;
  transition: 0.3s;
}

.rule-container {
  background-color: rgb(232 232 232);
  padding: 12px;
  margin-top: 4px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
}

.rule-container svg {
  cursor: pointer;
}

.logged-with-email-container {
  display: flex;
  justify-content: end;
}

.logged-with-email {
  text-align: right;
  padding-top: 10px;
  font-size: 12px;
}

.loading-plan-button {
  width: 80px;
  padding-left: 48px;
  padding-right: 48px;
}

.checkbox-wrapper * {
  box-sizing: border-box;
}

.checkbox-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.checkbox-wrapper .hidden-checkbox-input {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox-wrapper .checkbox-label {
  background-color: #fff;
  padding: 16px;
  color: #8549ba;
  font-weight: 600;
  border: 1px solid #8a05be;
  border-radius: 1em;
  cursor: pointer;
  align-items: center;
  display: flex;
  margin: 0;
}

.checkbox-wrapper .hidden-checkbox-input:checked + label {
  color: white;
  background-color: #8a05be1c;
  border-color: #4e016d;
}

.checkbox-wrapper .checkbox-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #8549ba;
  transition: background-color 0.3s ease-in-out;
}

.checkbox-wrapper .hidden-checkbox-input:checked + .checkbox-icon-wrapper {
  background-color: #8a05be;
}

.leagues-to-watch-length {
  color: #8549ba;
}

.add-rule-btn-wrapper {
  justify-content: flex-end;
}

.dashboard-container {
  padding-bottom: 60px;
}

.dashboard-cta-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  box-sizing: border-box;
}

.flex-justify-center {
  justify-content: center;
  display: flex;
}

div.form-group .text-input {
  box-sizing: border-box;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 16px;
  font-size: 16px;
  letter-spacing: 0;
  color: #333;
}

div.form-group .text-input::placeholder {
  font-size: 16px;
  letter-spacing: 0;
  color: hsl(0, 0%, 50%);
}

.input-error {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

.form-odds-monitor {
  background-color: rgb(244, 234, 255, 0.5);
  outline: 0.5px solid #4e016d;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
}

.form-group,
.form-odds-monitor .market-label {
  margin-top: 0;
}

.input-disclaimer {
  margin-top: 16px;
  font-size: 14px;
}

.required-signal {
  color: red;
}

.submit-disclaimer {
  margin-top: 8px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.youtube-modal-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btn-close-youtube-modal {
  margin-bottom: 32px;
  align-self: end;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
}

.youtube-player {
  box-sizing: border-box;
  width: 100%;
  height: 80%;
}

.youtube-player > iframe {
  width: 100%;
  height: 100%;
}

.loader--youtube-player {
  width: 36px;
  height: 36px;
  margin-left: 4px;
  border: 4px solid gray;
  border-bottom-color: black;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  align-self: center;
  margin-top: 80%;
  position: absolute;
}

.checkout {
  margin: 40px 0;
}

.checkout .cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.checkout .card {
  background: #fff;
  color: hsl(233, 13%, 49%);
  border-radius: 0.8rem;
}

.checkout .cards .card.active {
  background: rgb(133, 73, 186);
  background: linear-gradient(
    135deg,
    rgba(133, 73, 186, 1) 0%,
    rgba(72, 40, 99, 1) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  z-index: 1;
}
.checkout ul {
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0;
}
.checkout ul li {
  list-style-type: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
}
.checkout ul li.price {
  font-size: 4rem;
  font-weight: 700;
  color: hsl(232, 13%, 33%);
  display: flex;
  align-items: baseline;
  padding: 0 0 0rem;
}

.checkout ul li.discount {
  font-style: italic;
  border-radius: 15px;
  border: 1px solid white;
  color: white;
  padding: 10px 15px;
}

.checkout ul li.discount1.padding {
  padding: 0 0 1rem;
}

.checkout ul li.discount1 {
  font-style: italic;
  font-size: 0.6rem;
  font-weight: regular;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout ul li.discount1 span {
  margin: 0 4px;
}

.checkout ul li.price span {
  font-size: 0.8rem;
  line-height: 3rem;
}

.checkout .shadow {
  box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.1);
}

.checkout .card.active .price {
  color: #fff;
}

.checkout .btn {
  cursor: pointer;
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #8549ba;
  background-color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
}
.checkout .active-btn {
  background: #fff;
  color: #8549ba;
}
.checkout .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
}
.checkout .card.active .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.253);
}
.checkout .pack {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 280px) {
  .checkout ul {
    margin: 1rem;
  }
  .checkout h1 {
    font-size: 1.2rem;
  }
  .checkout .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }
  .checkout .cards {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .checkout .card {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }
  .checkout .cards .card.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .checkout ul {
    margin: 20px;
  }
  .checkout .cards {
    display: flex;
    flex-direction: column-reverse;
  }
  .checkout .card {
    margin-bottom: 1rem;
  }
  .checkout .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .checkout .cards {
    display: flex;
    flex-direction: column-reverse;
  }
  .checkout .card {
    margin-bottom: 1rem;
  }
  .checkout .cards .card.active {
    transform: scale(1);
  }
}
@media (min-width: 414px) and (max-width: 768px) {
  .checkout .cards {
    display: flex;
    flex-direction: column-reverse;
  }

  .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .cards .card.active {
    transform: scale(1);
  }
}
@media (min-width: 768px) and (max-width: 1046px) {
  .checkout .cards {
    display: flex;
  }
  .checkout .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .checkout .cards .card.active {
    transform: scale(1);
  }
}

@media (max-width: 1200px) {
  .mobile-card {
    margin-bottom: 16px;
  }
}

/* CSS */
.button-30 {
  width: 100%;
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #8549ba;
  cursor: pointer;
  display: inline-flex;
  font-weight: bold;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  margin: 0rem 0 0.5rem;
}

.button-30:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}
